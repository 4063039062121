<template>
  <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm">
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <calendar-left-sidebar-content @open-evnt-handler-drawer="isEventHandlerDrawerActive = true"></calendar-left-sidebar-content>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      stateless
      width="420"
    >
      <calendar-event-handler-drawer-content
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeConfirm"
        @add-event="addEvent"
        @update-event="updateEvent"
        @close-drawer="isEventHandlerDrawerActive = false"
      ></calendar-event-handler-drawer-content>
    </v-navigation-drawer>

    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this workout?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="removeEvent"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      :style="contentStyles"
      class="h-full"
    >
      <div
        v-if="refCalendar"
        class="calendar-header mx-6 my-5 d-flex align-center flex-wrap"
      >
        <v-btn
          icon
          class="d-inline-block d-md-none me-1"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="30">
            {{ icons.mdiMenu }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-1"
          @click="refCalendar.prev()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-3"
          @click="refCalendar.next()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refCalendar.title }}
        </p>

        <v-spacer></v-spacer>

        <v-btn-toggle
          v-model="activeCalendarView"
          color="primary"
          class="mt-3 mt-sm-0"
        >
          <v-btn
            v-for="calendarViewOption in calendarViewOptions"
            :key="calendarViewOption"
            elevation="0"
            :value="calendarViewOption"
          >
            {{ calendarViewOption }}
          </v-btn>
        </v-btn-toggle>

      </div>

      <v-calendar
        ref="refCalendar"
        v-model="calendarValue"
        :type="activeCalendarView"
        :events="calendarEvents"
        event-start="workout_date"
        event-end="workout_date"
        :event-name="getWorkoutLabel"
        :event-margin-bottom="5"
        :weekdays="weekdays"
        :event-text-color="getWorkoutTextColor"
        :event-color="getWorkoutColor"
        @click:event="calenderHandleEventClick"
        @click:day="calendarHandleDayClick"
        @click:time="calendarHandleDayClick"
        @click:more="calendarHandleMoreClick"
        @change="fetchEvents"
      ></v-calendar>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import { mdiChevronLeft, mdiChevronRight, mdiMenu } from '@mdi/js'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import store from '@/store'
import { hexToRgb, getVuetify } from '@core/utils'
import calendarStoreModule from './calendarStoreModule'
import moment from 'moment'

// Local Components
import CalendarLeftSidebarContent from './CalendarLeftSidebarContent.vue'
import CalendarEventHandlerDrawerContent from './CalendarEventHandlerDrawerContent.vue'

export default {
  components: {
    CalendarLeftSidebarContent,
    CalendarEventHandlerDrawerContent,
  },
  setup() {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    // ————————————————————————————————————
    //* ——— Vuetify Instance
    // ————————————————————————————————————

    const $vuetify = getVuetify()

    // ————————————————————————————————————
    //* ——— Left Sidebar
    // ————————————————————————————————————
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // ————————————————————————————————————
    //* ——— Templare Ref
    // ————————————————————————————————————

    const refCalendar = ref(null)

    // ————————————————————————————————————
    //* ——— Calendar View/Type
    // ————————————————————————————————————

    const activeCalendarView = ref('month')
    const calendarViewOptions = ['month', 'week', 'day']

    // ————————————————————————————————————
    //* ——— Calendar Value & Events
    // ————————————————————————————————————

    const calendarValue = ref()
    const calendarEvents = ref([])

    const weekdays = [1, 2, 3, 4, 5, 6, 0]

    let userData = JSON.parse(localStorage.getItem('userData'))

    const blankEvent = {
      workout_id: '',
      workout_date: moment().format("YYYY-MM-DD"),
      length: '',
      sport_id: '',
      created_by_id: userData.id,
      location: '',
      notes: '',
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    const fetchEvents = (interval) => {

      // modify start and end date to return all visible days in calendar month vue
      const _start = moment(interval.start.date).startOf("week").format('YYYY-MM-DD')
      const _end = moment(interval.end.date).endOf("week").format('YYYY-MM-DD')

      interval.start = _start
      interval.end = _end
      interval.sports = selectedSports.value.join(",")

      store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchEvents`,interval)
        .then(response => {
          const events = response.data
          calendarEvents.value = events
        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })
    }

    const getWorkoutTextColor = e => e.sport.label_textcolor
    const getWorkoutColor = e => e.sport.label_color
    const getWorkoutLabel = e => {
      let _label = e.input.sport.name
      if (e.input.location != "") {
        _label += " (" + e.input.location + ")"
      }
      return _label
    }

    //
    //* ——— Calendar Event Handler ——————————————————
    //

    // TODO: Remove this once issue is resolved
    // Please check: https://github.com/vuetifyjs/vuetify/issues/13900
    const eventClickBugWorkaround = ref(false)

    const calenderHandleEventClick = eventObj => {
      // Grab only event object
      event.value = eventObj.event

      // eslint-disable-next-line no-use-before-define
      isEventHandlerDrawerActive.value = true

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    const calendarHandleDayClick = dayObj => {
      // Bug workaround
      if (eventClickBugWorkaround.value) {
        eventClickBugWorkaround.value = false

        return
      }

      event.value.workout_date = dayObj.date

      // eslint-disable-next-line no-use-before-define
      isEventHandlerDrawerActive.value = true
    }

    const calendarHandleMoreClick = ({ date }) => {
      calendarValue.value = date

      activeCalendarView.value = 'day'

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    //
    //* ——— Event Actions ——————————————————
    //

    const addEvent = eventData => {
      store.dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/addEvent`, { event: eventData }).then(() => {
        const sportIndex = store.state['app-calendar'].calendarSports.findIndex(sport => sport.sport_id === eventData.sport_id)
        eventData.sport = store.state['app-calendar'].calendarSports[sportIndex]
        calendarEvents.value.push(eventData)
      })
    }

    const updateEvent = eventData => {
      store.dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/updateEvent`, { event: eventData }).then(response => {
        const updatedEvent = response.data.event

        updatedEvent.color = store.state[CALENDAR_APP_STORE_MODULE_NAME].calendarOptions.find(
          calendar => calendar.label === updatedEvent.extendedProps.calendar,
        ).color

        const updatedEventIndex = calendarEvents.value.findIndex(e => e.id === updatedEvent.id)

        calendarEvents.value.splice(updatedEventIndex, 1, updatedEvent)
      })
    }

    const dialogDelete = ref(false)

    const removeConfirm = () => {
      dialogDelete.value = true
    }

    const removeEvent = () => {
      const eventId = event.value.workout_id
      store.dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/removeEvent`, { id: eventId }).then(() => {
        const updatedEventIndex = calendarEvents.value.findIndex(e => e.workout_id === eventId)
        calendarEvents.value.splice(updatedEventIndex, 1)
        dialogDelete.value = false
        isEventHandlerDrawerActive.value = false
      })
    }

    //
    //* ——— Selected Calendars ——————————————————
    //
    const selectedSports = computed(() => store.state[CALENDAR_APP_STORE_MODULE_NAME].selectedSports)

    watch(selectedSports, () => {
      fetchEvents({
        start: {
          date: refCalendar.value._data.lastStart.date
        },
        end: {
          date: refCalendar.value._data.lastEnd.date
        }
      })
    })

    // ————————————————————————————————————
    //* ——— Event Handler
    // ————————————————————————————————————

    const isEventHandlerDrawerActive = ref(false)
    watch(isEventHandlerDrawerActive, val => {
      if (!val) clearEventData()
    })

    return {
      // Left Sidebar
      isLeftSidebarOpen,
      contentStyles,

      // Template Refs
      refCalendar,

      getWorkoutTextColor,
      getWorkoutColor,
      getWorkoutLabel,

      // Calendar View/Type
      activeCalendarView,
      calendarViewOptions,

      // Calendar Value & Events
      calendarValue,
      calendarEvents,
      event,
      clearEventData,
      weekdays,

      dialogDelete,
      removeConfirm,

      calenderHandleEventClick,
      calendarHandleDayClick,
      calendarHandleMoreClick,

      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,

      // Event handler sidebar
      isEventHandlerDrawerActive,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
</style>
