<template>
  <div class="ma-5">
    <v-btn
      block
      color="primary"
      @click="$emit('open-evnt-handler-drawer')"
    >
      Add Workout
    </v-btn>

    <!-- Calendars -->
    <p class="text-xs text--disabled mt-8 mb-1">
      Sports
    </p>

    <!-- Calendar Options -->
    <v-checkbox
      v-model="checkAll"
      label="View All"
    ></v-checkbox>
    <v-checkbox
      v-for="item in calendarSports"
      :key="item.sport_id"
      v-model="selectedSports"
      :value="item.sport_id"
      :color="item.color"
      :label="item.name"
      class="mt-2"
      hide-details
    >
    </v-checkbox>
  </div>
</template>

<script>
import store from '@/store'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import { hexToRgb } from '@core/utils'

export default {
  setup() {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const calendarSports = ref([])

    store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchSports`)
        .then(response => {
          calendarSports.value = response.data

          const selectedSports = []

          calendarSports.value.map(sport => {
            const rgbColor = hexToRgb(sport.label_color)
            sport.color = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`

            selectedSports.push(sport.sport_id)

          })

          store.commit('app-calendar/SET_SPORTS', calendarSports.value)
          store.commit('app-calendar/SET_SELECTED_CALENDARS', selectedSports)

        })
        .catch(error => {
          // console.error(error)
          // console.error(error.response)
        })

    // ------------------------------------------------
    // selectedSports
    // ------------------------------------------------
    const selectedSports = computed({
      get: () => store.state['app-calendar'].selectedSports,
      set: val => {
        store.commit('app-calendar/SET_SELECTED_CALENDARS', val)
      },
    })

    // ------------------------------------------------
    // checkAll
    // ------------------------------------------------
    const checkAll = computed({
      /*
      GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
      SET: If value is `true` => then add all available options in selected filters => Select All
           Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
    */
      get: () => selectedSports.value.length === calendarSports.value.length,
      set: val => {
        if (val) {
          selectedSports.value = calendarSports.value.map(i => i.sport_id)
        } else if (selectedSports.value.length === calendarSports.value.length) {
          selectedSports.value = []
        }
      },
    })

    return {
      calendarSports,
      selectedSports,
      checkAll,
    }
  },
}
</script>

<style>
</style>
